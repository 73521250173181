.prostate-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.prostate-page-content {
  max-width: 700px;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  /* padding: 16px;
  margin-top: 24px; */
}

.error-label {
  color: red;
}

.error-label > .error-text {
  font-size: 12px;
}

.bottom-gray-class {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.bg-onco-image {
  background-image: url('../../../assets/images/onco-background.png');
  background-repeat: no-repeat;
  background-position: -39px 0;
  background-size: 120% 100%;
}

.heartfelt-background {
  background-image: url('../../../assets/images/hearl-felt-background.png');
  background-repeat: no-repeat;
  padding-bottom: 96px;
  background-size: 100% 100%;
}
.request-callback-bg {
  background-image: url('../../../assets/images/request-callback-bg.png');
  background-repeat: no-repeat;
  padding: 96px 0;
  background-size: 100% 100%;
}
