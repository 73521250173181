.home-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.content {
  max-width: 700px;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  margin-top: 24px;
}

.error-label {
  color: red;
}

.error-label > .error-text {
  font-size: 12px;
}
